import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import "../styles/global.css"

import Store from "../components/store"
import ContainerMain from "../components/containermain"
import ContainerCards from "../components/containercards"
import HeaderMobile from "../components/headermobile"
import Footer from "../components/footer"
import SideBar from "../components/sidebar"
import Wrapper from "../components/wrapper"

export default ({ data }) => {
  return (
    <Store>
      <Store.Consumer>
        {({ changeIsHambOpen, isHambOpen }) => (
          <Wrapper resized={() => changeIsHambOpen(false)}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{data.dataJson.sitemeta.metaTitle}</title>
              <meta
                name="description"
                content={data.dataJson.sitemeta.metaDescription}
              />
              <style>
                {`body { 
                background: ${data.dataJson.sitemeta.bgColor};
              }`}
              </style>
              <html lang="sk" />
            </Helmet>
            <HeaderMobile
              closed={isHambOpen}
              bgColor={data.dataJson.sitemeta.bgColor}
              menuName={data.dataJson.sitemeta.menuName}
            />
            <ContainerMain>
              <SideBar />
              <ContainerCards sitecontent={data.dataJson.sitecontent} />
            </ContainerMain>
            <Footer />
          </Wrapper>
        )}
      </Store.Consumer>
    </Store>
  )
}

export const query = graphql`
  query($slug: String!) {
    dataJson(sitemeta: { slug: { eq: $slug } }) {
      sitecontent {
        links {
          href
          label
        }
        title
      }
      sitemeta {
        slug
        menuName
        bgColor
        metaTitle
        metaDescription
      }
      id
    }
  }
`