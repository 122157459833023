import React from "react"
import styled from "styled-components"
import CardLink from "../components/cardlink"
import ContainerCardsStyles from "./containercards.module.css"


const Card = styled.div`
  margin: 0 0 20px 0;
  @media screen and (max-width: 549px) {
    padding: 0px 0px 24px 0px;
    width: 296px;  
}
  @media screen and (min-width: 549px) {
    padding: 0px 12px 24px 12px;
    width: 240px;  
}
`

const CardTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  color: #ffffff;
  margin-bottom: 20px;
  margin-top: 0px;
  border-bottom: 1px solid rgb(255, 255, 255, 0.2);
`
const ContainerCards = props => (
    <div className={"grid " + ContainerCardsStyles.containercards} role="main">
        {props.sitecontent.map((item, index) => (
            <Card key={index} className="grid-item">
                <CardTitle>{item.title}</CardTitle>
                {item.links.map((link, index) => {
                    return (
                        <CardLink
                            key={index}
                            href={link.href}
                            linkText={link.label}
                        ></CardLink>
                    )
                })}
            </Card>
        ))}
    </div>
)

export default ContainerCards